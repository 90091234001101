/**********************************************************************/
/**********************************************************************/
body {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100vw;
	min-height: 100vh;
	margin: 0;
	padding: 0;
	background-color: #FFFFFF;
	font-weight: 700;
	font-family: "Montserrat", sans-serif;
    color: #000000;
	cursor: default;
	overflow-x: hidden;

	* {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
	}

	/******************************************************************/
	img {
		display: block;
		max-width: 100%;
	}
	
	a {
		display: inline-block;
		text-decoration: none;
		outline: none;
	}


	/******************************************************************/
	/******************************************************************/
	.banner {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 47.5vw;
		background: linear-gradient(269.92deg, rgba(80, 240, 0, 0.35) 0.05%, rgba(80, 240, 0, 0) 99.92%);
		//background-size: 400% 400%;
		animation: gradient 10s ease infinite;

		@media only screen and (orientation: portrait) {
			height: 176vw;
		}

		&:before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background-color: #000000;
			z-index: -1;
		}

		/*@keyframes gradient {
			0% { background-position: 0% 50%; }
			50% { background-position: 100% 50%; }
			100% { background-position: 0% 50%; }
		}

		&__lights {
			pointer-events: none;

			.light {
				position: absolute;
				top: 100vh;
				bottom: 0;
				left: 0;
				right: 0;
				width: 0;
				opacity: 0;
				box-shadow: #e9f1f1 0px 0px 20px 2px;
				background-color: #FFFFFF;
				animation: floatUp 4s infinite linear;

				@keyframes floatUp {
					0% {
						top: 100vh;
						opacity: 0;
					}
					
					25% {
						opacity: 1;
					}
					
					50% {
						top: 0;
						opacity: 0.8;
					}
					
					75% {
						opacity: 1;
					}
					
					100% {
						top: -100vh;
						opacity: 0;
					}
				}
				
				&-2 {
					left: 15%;
					transform: scale(1.6);
					animation-delay: 7s;
				}
				
				&-3 {
					left: -15%;
					transform: scale(0.5);
					animation-delay: 2.5s;
				}
				
				&-4 {
					left: -34%;
					transform: scale(1.2);
					animation-delay: 4.5s;
				}
				
				&-5 {
					left: -57%;
					transform: scale(2.2);
					animation-delay: 8s;
				}
				
				&-6 {
					left: -81%;
					transform: scale(0.8);
					animation-delay: 3s;
				}
				
				&-7 {
					left: 37%;
					transform: scale(3.2);
					animation-delay: 5.3s;
				}
				
				&-8 {
					left: 62%;
					transform: scale(1.7);
					animation-delay: 4.7s;
				}
				
				&-9 {
					left: 85%;
					transform: scale(0.9);
					animation-delay: 4.1s;
				}
			}
		}*/
		
		&__content {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 29vw;
			margin: auto;
			padding-top: 4vw;

			@media only screen and (orientation: portrait) {
				width: 77vw;
				padding-top: 12.5vw;
			}
		}

		&__logo {
			margin-bottom: 4.5vw;

			@media only screen and (orientation: portrait) {
				margin-bottom: 13.9vw;
			}
		}

		&__text {
			margin-bottom: 1.6vw;
			font-size: 1.1vw;
			line-height: 115%;
			color: #FFFFFF;
			text-align: center;

			@media only screen and (orientation: portrait) {
				margin-bottom: 5.9vw;
				font-size: 3.9vw;
			}
		}

		&__btn {
			margin: 0 auto 4.5vw;
			background-color: #50F000;
			padding: 1.55vw 1.9vw 1.35vw;
			border-radius: 1.65vw;
			font-size: 1.12vw;
			line-height: 100%;
			color: #000000;
			transition: transform 0.3s;

			&:hover {
				transform: scale(1.05);
			}

			@media only screen and (orientation: portrait) {
				margin-bottom: 14.2vw;
				padding: 5.3vw 6.7vw;
				border-radius: 4vw;
				font-size: 4.1vw;
			}
		}

		&__qr {
			width: 35%;

			@media only screen and (orientation: portrait) {
				width: 60%;
			}
		}
	}

	
	/******************************************************************/
	/******************************************************************/
	.advantages {
		width: 40.6vw;
		margin: 6.3vw auto 4.4vw;

		@media only screen and (orientation: portrait) {
			width: 80vw;
			margin-top: 11.2vw;
			margin-bottom: 10.1vw;
		}

		&__row {
			display: flex;
			justify-content: space-between;

			.advantage {
				position: relative;
				display: flex;
				flex-direction: column;
				width: 22.2%;
				margin-bottom: 6.1vw;

				@media only screen and (orientation: portrait) {
					width: 25.3%;
					margin-bottom: 13.9vw;
				}

				img {
					width: 100%;
				}

				p {
					position: absolute;
					top: calc(100% + 1.2vw);
					left: 50%;
					transform: translateX(-50%);
					font-size: 1.1vw;
					line-height: 115%;
					text-align: center;
					white-space: nowrap;

					@media only screen and (orientation: portrait) {
						top: calc(100% + 2.1vw);
						font-size: 2.3vw;
					}
				}
			}
		}
	}

	
	/******************************************************************/
	/******************************************************************/
	.cta {
		display: flex;
		flex-direction: column;
		width: 40.6vw;
		margin: 0 auto 4vw;

		@media only screen and (orientation: portrait) {
			width: 85vw;
			margin-bottom: 13.6vw;
		}

		&__title {
			margin-bottom: 1.9vw;
			font-size: 3.45vw;
			line-height: 100%;
			text-align: center;

			@media only screen and (orientation: portrait) {
				margin-bottom: 12.3vw;
				font-size: 7.3vw;
			}
		}

		&__btns {
			display: flex;
			justify-content: center;

			@media only screen and (orientation: portrait) {
				justify-content: space-between;
			}
		}

		&__btn {
			display: flex;
			height: 2.6vw;
			margin: 0 1.2vw;
			transition: transform 0.3s;

			&:hover {
				transform: scale(1.05);
			}

			@media only screen and (orientation: portrait) {
				height: 12.2vw;
				margin: 0;
			}
		}
	}
}